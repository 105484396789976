import { Suspense, lazy } from "react";
import Fallback from "Components/Fallback";
const Layout = lazy(() => import("Layout"));

function App() {
    return (
        <Suspense fallback={<Fallback />}>
            <Layout />
        </Suspense>
    );
}

export default App;
